.sidebar {
    animation: fadeInLeft;
    animation-duration: 2s;
    flex-direction: column;
    text-align: center;
    display: table-cell;
    border-right: white 1px dotted;
    background-color: transparent;
}

.sidebar .header {
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    width: 100px;
    height: 100px;
}

.sidebar .body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
}

.sidebar .body .nav {
    border-bottom: white 1px dashed;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    transition: background-color 0.5s;
}

.sidebar .body .nav:hover {
    background-color: rgba(88, 188, 80, 0.61);
}

.sidebar .footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 20% 0;
}

.footer .icon {
    color: white;
    animation: rubberBand;
    animation-duration: 2s;
    animation-delay: 2s;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
}

.footer .icon:hover {
    color: #fca311;
}


.navbar-brand {
    color: white;
}

.navbar-nav .nav-link.active, .navbar-nav .show > .nav-link, .nav-link {
    color: rgba(255, 255, 255, 0.5);
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
}

.navbar-nav .nav-link.active:hover, .navbar-nav .show > .nav-link:hover, .nav-link:hover {
    color: white;
}

.collapsable-navbar .footer {
    padding: 10% 0 0;
}

.collapsable-navbar .footer .col {
    display: flex;
    justify-content: center;
}

.collapsable-navbar {
    margin: 10px 5%;
}

.collapsable-navbar .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsable-navbar .icon {
    width: 50px;
    height: 50px;
    animation: rubberBand;
    animation-duration: 2s;
    animation-delay: 2s;
}

.collapsable-navbar .icon:hover {
    color: #fca311;
}