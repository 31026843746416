.App {
    color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.App::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}


h1 {
    font-size: 4rem;
}

/* Enable scrolling on .content */
.content {
    overflow-y: scroll;
    animation: bounceInRight;
    animation-duration: 2s;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content::-webkit-scrollbar {
    display: none;
}


