h2 {
    font-size: 32px;
}

h1 {
    font-size: 72px;
    font-weight: bold;
}

.greeting {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.resume {
    margin-top: 5%;
    animation: swing;
    animation-delay: 2s;
    animation-duration: 2s;
}

.resume button {
    background-color: #008a8a;
    color: black;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1.2em;
}

.languages {
    height: 98vh;
    margin-top: 5%;
}

.languages .container-fluid {
    height: 98vh;
    animation: fadeIn;
    animation-duration: 2s;
    overflow-y: hidden;
}

.languages .container-fluid .row {
    margin: 10px;
    height: 25vh;
    display: grid;
    position: relative;
    place-items: center;
}

.languages .container-fluid .row img {
    transform: scale(1);
    width: 100px;
    height: auto;
    position: absolute;

    animation: fadeIn;
    animation-duration: 2s;
}

.technologies {
    height: 98vh;
    margin-top: 5%;
}

.technologies .container-fluid {
    height: 98vh;
    animation: fadeIn;
    animation-duration: 2s;
    overflow-y: hidden;
}

.technologies .container-fluid .row {
    height: 25vh;
    display: grid;
    position: relative;
    place-items: center;
}

.technologies .container-fluid .row img {
    transform: scale(1);
    width: 100px;
    height: auto;
    position: absolute;
    animation: fadeIn;
    animation-duration: 2s;
}

.technologies .container-fluid::-webkit-scrollbar, .languages .container-fluid::-webkit-scrollbar {
    display: none;
    overflow: hidden;
    -ms-overflow-style: none;
}