.skill-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #E7E7E7;
    border-radius: 5px;
    margin: 5px;
    padding: 5px 15px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
}

.skill-card p {
    color: rgba(0, 0, 0, 0.7);
    margin: 0;
    padding: 0;
}

.skill-card:hover {
    transform: translateY(-5px);
}